import {
  AudioPlacesType,
  LightObjectsInterface,
  LightStateInterface, MaterialType,
  MirrorObject,
  SettingsType,
  ShopifyAssetsType,
  TeleportType,
  VideoBannersType,
} from '../../assets/types';
import { PlaceMenuItem } from './AbstractSpaceGenerator';

export enum PreDefinedAvatarCode {
  BASE_MALE = 'male_base',
  BASE_FEMALE = 'female_base',
  UNO_BASE_MALE = 'uno_male_base',
  UNO_BASE_FEMALE = 'uno_female_base',
  YUKI = 'yuki',
  YUKI_HACKER = 'yuki_hacker',
  MIRA = 'mira',
  MIRA_SPACE = 'mira_space',
}

export enum PreDefinedAnimationsPacks {
  MALE = 'male',
  FEMALE = 'female',
}

export type CustomMenuConfig = {
  id: string;
  imgUrl: string;
};

export type PreDefinedAvatarConfig = {
  code: PreDefinedAvatarCode;
};

export type CustomAvatarsConfig = {
  code: string;
  model: string;
  animations: PreDefinedAnimationsPacks;
};

export type AvatarConfig = (PreDefinedAvatarConfig | CustomAvatarsConfig) & {
  isDefault?: boolean;
  hideFromMenu?: boolean;
  menu?: CustomMenuConfig;
};

export type NewAvatarsConfig = AvatarConfig[];

export type SpaceConfig = {
  settings: SettingsType;
  colliderModelUrl: string;
  spaceModelUrl: string;
  lightStates?: LightStateInterface;
  lightObjects?: LightObjectsInterface;
  videoBanners?: VideoBannersType;
  audioPlaces?: AudioPlacesType;
  shopifyAssets?: ShopifyAssetsType;
  shadowReceiveObjects?: string[];
  placesMenuObjects?: PlaceMenuItem[];
  teleports?: TeleportType[];
  mirrorsConfigs?: MirrorObject[];
  wireframeObjects?: string[];
  avatars: NewAvatarsConfig;
  materials?: MaterialType[];
};
