export type UserInfo = {
  name: string;
  id: string;
};

export enum AudioUserStatus {
  Disable = 'Disable',
  Active = 'Active',
  Muted = 'Muted',
}

export enum VideoStatus {
  Disabled = 'Disabled',
  Muted = 'Muted',
  ActiveMain = 'ActiveMain',
  ActiveShare = 'ActiveShare',
  ActiveMainAndShare = 'ActiveMainAndShare',
}

export interface ConferenceServiceInterface {
  get audioService(): AudioServiceInterface | undefined;
  get videoService(): VideoServiceInterface | undefined;
  setAudioService(service: AudioServiceInterface): ConferenceServiceInterface;
  setVideoService(service: VideoServiceInterface): ConferenceServiceInterface;
  initialize(): Promise<void>;
  connect(user: UserInfo): Promise<void>;
  createAndJoinConference(roomName: string): Promise<void>;
}

export interface AudioServiceInterface {
  localAudioEnabled: boolean;
  localSoundEnabled: boolean;
  askPermissionsForMic(): Promise<void>;
  getUserAudioStatus(externalId: string): AudioUserStatus;
  muteUserById(externalId: string, value: boolean): Promise<void>;
  muteAll(value: boolean): Promise<void>;
  get isAudioEnabled(): boolean;
  get isSoundEnabled(): boolean;
  toggleAudio(): Promise<void>;
  toggleSound(): Promise<void>;
  enableAudio(): Promise<void>;
  disableAudio(): Promise<void>;
}

export interface VideoServiceInterface {
  getUserStream(externalId: string): MediaStream | undefined;
  getUserShareStream(externalId: string): MediaStream | undefined;
  toggleVideo(): Promise<void>;
  get isVideoEnabled(): boolean;
  get isScreenShareEnabled(): boolean;
  enableScreenShare(): Promise<void>;
  disableScreenShare(): Promise<void>;
  toggleScreenShare(): Promise<void>;
  turnUserCameraById(externalId: string, value: boolean): Promise<void>;
  getUserVideoStatus(externalId: string): VideoStatus;
  getUserSpikingStatus(externalId: string): boolean;
}
