import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import { AudioServiceInterface, AudioUserStatus } from '../types';
import ConferenceService from './Conference.service';

export default class AudioService implements AudioServiceInterface {
  public localAudioEnabled = false;

  public localSoundEnabled = true;

  public changeAudioInProgress = false;

  public conference: ConferenceService;

  public constructor(conference: ConferenceService) {
    this.conference = conference;
  }

  get externService() {
    return this.conference.externService;
  }

  public async askPermissionsForMic() {
    try {
      // TODO: conference permissionsUpdated
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const permission = await navigator.permissions.query({ name: 'microphone' });
      if (!permission) return;
      this.localAudioEnabled = permission.state === 'granted';
      permission.onchange = () => {
        const enabled = permission.state === 'granted';
        if (enabled !== this.localAudioEnabled && !this.changeAudioInProgress) {
          this.localAudioEnabled = enabled;
          if (this.localAudioEnabled) this.enableAudio();
          else this.disableAudio();
        }
        // this.localAudioEnabled = permission.state === 'granted'
      };
    } catch (e) {
      console.log(e);
      return Promise.resolve();
    }
  }

  public afterJoin() {
    if (!this.localSoundEnabled) return this.muteAll(false);
    this.externService.conference.on('streamAdded', (participant) => {
      // console.log(participant);
      if (!this.localSoundEnabled && participant.id !== this.externService.session.participant.id) {
        return this.muteUser(participant, false);
      }
    });
    return Promise.resolve();
  }

  public getUserAudioStatus(externalId: string): AudioUserStatus {
    const user = this.conference.findUserById(externalId);
    if (!user) return AudioUserStatus.Disable;
    if (!user.audioTransmitting) return AudioUserStatus.Disable;
    if (!user.audioReceivingFrom) return AudioUserStatus.Muted;
    return AudioUserStatus.Active;
  }

  public muteUserById(externalId: string, value: boolean) {
    const user = this.conference.findUserById(externalId);
    if (!user) return Promise.resolve();
    return this.muteUser(user, value);
  }

  public muteUser(user: Participant, value: boolean) {
    if (!value) return this.externService.audio.remote.stop(user);
    return this.externService.audio.remote.start(user);
  }

  public muteAll(value: boolean) {
    const proms: Promise<any>[] = [];
    Array.from(this.externService.conference.participants)
      .filter(([name, user]) => user.id !== this.externService.session.participant.id)
      .forEach(([name, user]) => {
        proms.push(this.muteUser(user, value));
      });
    return Promise.all(proms).then(() => {});
  }

  public get isAudioEnabled() {
    return this.localAudioEnabled;
  }

  public get isSoundEnabled() {
    return this.localSoundEnabled;
  }

  public toggleAudio() {
    if (this.isAudioEnabled) return this.disableAudio();
    return this.enableAudio();
  }

  public toggleSound() {
    return this.muteAll(!this.localSoundEnabled).then(() => {
      this.localSoundEnabled = !this.localSoundEnabled;
    });
  }

  public enableAudio() {
    this.changeAudioInProgress = true;
    return this.externService.audio.local.start()
      .then(() => {
        this.localAudioEnabled = true;
        this.changeAudioInProgress = false;
      })
      .catch((err) => {
        //
      });
  }

  public disableAudio() {
    this.changeAudioInProgress = true;
    return this.externService.audio.local.stop()
      .then(() => {
        this.localAudioEnabled = false;
        this.changeAudioInProgress = false;
      });
  }
}
