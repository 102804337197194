import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { System } from '../../../engine/System';
import AvatarVideoComponent, { SessionPinnedVideoType } from '../../components/UI/AvatarVideo.component';
import VideoComponent from '../../components/VideoComponent';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { PanelId } from '../../ui/enum/PanelId';
import { InputSystem } from '../../../engine/systems/InputSystem';
import { UIBuilderSystem } from '../UIBuilder.system';
import { ButtonId } from '../../ui/enum/ButtonId';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import { setIconState } from '../../ui/helpers/setIconState';
import AvatarHelpers from '../../services/AvatarHelpers';
import { VideoStatus } from '../../../engine/network/services/chats/types';
import { ContainerId } from '../../ui/enum/ContainerId';
import { FPControllerComponent } from '../../components/FPController.component';

export default class AvatarVideoSystem extends System {
  onUpdate(dt: number) {
    super.onUpdate(dt);
    const components = this.app.componentManager.getComponentsByType(AvatarVideoComponent);
    this.calculatePinIndexes(components);
    components.forEach((component) => {
      if (!component.enabled) return;
      component.pinnedInCurrentFrame.update(component.pinned);
      component.pinIndexInCurrentFrame.update(component.pinIndex);
      this.handlePinPosition(component);
      this.handleUnpinPosition(component);
      this.handleUIReset(component);
      this.handleVisibility(component);
      this.handleVideoSource(component);
      this.handleLookAt(component);
      this.handlePin(component);
      this.handleActiveButtons(component);
      this.handleName(component);
      this.handleSpikingState(component);
    });
  }

  protected handleUnpinPosition(component: AvatarVideoComponent) {
    if (component.pinned) return;
    AvatarHelpers.setComponentPositionUnderAvatarHead(this.app, component,
      this.app.getSystemOrFail(UIBuilderSystem).getAdaptiveServiceByComponent(component), component.avatarEntity);
  }

  protected handlePinPosition(component: AvatarVideoComponent) {
    if (!component.pinIndexInCurrentFrame.changedInCurrentFrame() || !component.pinned) return;
    const pos = this.app.getSystemOrFail(UIBuilderSystem).getAdaptiveServiceByComponent(component)?.position;
    if (pos) {
      component.entity.position.copy(pos);
    }
  }

  protected calculatePinIndexes(components: AvatarVideoComponent[]) {
    let i = 0;
    components.filter((cmp) => cmp.pinned && cmp.entity.visible).forEach((cmp) => {
      cmp.pinIndex = i;
      i++;
    });
  }

  protected handleName(component: AvatarVideoComponent) {
    AvatarHelpers.setUIName(this.app, component, component.userId);
  }

  protected handleSpikingState(component: AvatarVideoComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const { userId } = component;
    const { videoService } = this.app.chatsService || {};
    if (!userId || !videoService) return;

    const status = videoService.getUserSpikingStatus(String(userId));
    const state = status ? UIDocumentElementState.Selected : UIDocumentElementState.Default;
    const videoElement = uIDocumentComponent.getElementById(PanelId.AvatarVideo);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (videoElement && videoElement.currentState !== state) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      videoElement.setState(state);
    }
    const nameElement = uIDocumentComponent.getElementById(ContainerId.UserName);
    if (nameElement) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nameElement.setState(state);
      const textElement = nameElement.children.find((ch) => ch instanceof ThreeMeshUI.Text);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (textElement) textElement.setState(state);
    }
  }

  protected handleVisibility(component: AvatarVideoComponent) {
    // Do not show the user the video component in first person view
    if (component.userId === this.app.networkManager?.networkId && !component.pinned) {
      this.app.componentManager.getComponentsByType(FPControllerComponent).forEach((fpComponent) => {
        component.entity.visible = !fpComponent.enabled;
      });
    }
  }

  protected handleActiveButtons(component: AvatarVideoComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    const { videoService } = this.app.chatsService || {};
    const { userId } = component;
    if (!userId || !videoService) return;

    const cameraStatus = videoService.getUserVideoStatus(String(userId));
    const cameraEnabled = cameraStatus === VideoStatus.ActiveMain || cameraStatus === VideoStatus.ActiveMainAndShare;
    const countOfPinnedComponent = this.app.componentManager.getComponentsByType(AvatarVideoComponent)
      .filter((cmp) => cmp.pinned && cmp.entity.visible).length;

    if (states[ButtonId.PinButton]?.state === UIDocumentElementState.Active && (component.pinned || countOfPinnedComponent < 3)) {
      component.pinned = !component.pinned;
    }

    if (countOfPinnedComponent > 3) {
      component.pinned = false;
    }

    if (states[ButtonId.CamButton]?.state === UIDocumentElementState.Active) {
      if (userId === this.app.networkManager?.networkId) { // own camera
        videoService.toggleVideo();
      } else {
        videoService.turnUserCameraById(String(userId), !cameraEnabled);
      }
    }

    setIconState(uIDocumentComponent, ButtonId.PinButton,
      !component.pinned ? UIDocumentElementState.Default : 'alt');
    setIconState(uIDocumentComponent, ButtonId.CamButton,
      !cameraEnabled ? UIDocumentElementState.Default : 'alt');
  }

  protected handleInput(component: AvatarVideoComponent) {
    const input = this.app.getSystemOrFail(InputSystem);
    if (input.keyboard.getKeyByCode('Digit9').wasPressedThisFrame) {
      component.pinned = !component.pinned;
    }
  }

  protected handlePin(component: AvatarVideoComponent) {
    if (component.pinnedInCurrentFrame.changedInCurrentFrame()) {
      const uiBuilder = this.app.getSystemOrFail(UIBuilderSystem);
      const uiEntity = uiBuilder.findEntityDataByComponent(component);
      if (uiEntity) {
        uiBuilder.updateEntity(uiEntity);
      }
    }
  }

  protected handleUIReset(component: AvatarVideoComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    if (uIDocumentComponent.reset) {
      const videoComponent = component.entity.getComponentOrFail(VideoComponent);
      videoComponent.targetMesh = uIDocumentComponent.getElementById(PanelId.AvatarVideo) as ThreeMeshUI.Block;
      videoComponent.setUpTexture();
      uIDocumentComponent.reset = false;
    }
  }

  protected handleVideoSource(component: AvatarVideoComponent) {
    const { userId } = component;
    const videoService = this.app.chatsService?.videoService;
    const videoComponent = component.entity.getComponent(VideoComponent);
    if (userId && videoService) {
      const stream = videoService.getUserStream(String(userId));
      if (!videoComponent?.isInitialized || !stream) {
        component.entity.visible = false;
        return;
      }
      if (videoComponent.source?.stream === stream) {
        component.entity.visible = true;
        return;
      }
      videoComponent?.setSource({ stream }).then(() => {
        component.entity.visible = true;
      });
    }
  }

  protected handleLookAt(component: AvatarVideoComponent) {
    if (!this.app.camera) return;
    if (!component.pinned) {
      return component.entity.lookAt(this.app.camera.getWorldPosition(new Three.Vector3()));
    }
    if (!component.entity.rotation.equals(new Three.Euler(0, 0, 0))) {
      component.entity.rotation.set(0, 0, 0);
    }
  }
}
