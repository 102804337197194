import * as Three from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { Entity } from '../../../engine/Entity';
import { System } from '../../../engine/System';
import AvatarNamePanelComponent from '../../components/UI/AvatarNamePanel.component';
import AvatarHelpers from '../../services/AvatarHelpers';
import { UIBuilderSystem } from '../UIBuilder.system';
import { UIDocumentComponent } from '../../../engine/components/UIDocument.component';
import { ButtonId } from '../../ui/enum/ButtonId';
import { UIDocumentElementState } from '../../../engine/systems/UIDocument.system';
import { setIconState } from '../../ui/helpers/setIconState';
import NetworkObjectComponent from '../../../engine/components/NetworkObject.component';
import { VideoStatus } from '../../../engine/network/services/chats/types';
import { ContainerId } from '../../ui/enum/ContainerId';

export default class AvatarNamePanelSystem extends System {
  public onUpdate(ts: number) {
    this.app.componentManager.getComponentsByType(AvatarNamePanelComponent).forEach((component) => {
      this.handleVisible(component);
      if (!component.entity.visible) return;
      this.handleLookAt(component);
      this.handlePosition(component);
      this.handleName(component);
      this.handleSpikingState(component);
      this.handleActiveButtons(component);
    });
  }

  protected handleVisible(component: AvatarNamePanelComponent) {
    if (!component.videoPanelComponent) {
      component.entity.visible = true;
      return;
    }
    component.entity.visible = component.videoPanelComponent.pinned || !component.videoPanelComponent.entity.visible;
  }

  protected handleLookAt(component: AvatarNamePanelComponent) {
    if (!this.app.camera) return;
    component.entity.lookAt(this.app.camera.getWorldPosition(new Three.Vector3()));
  }

  protected handlePosition(component: AvatarNamePanelComponent) {
    AvatarHelpers.setComponentPositionUnderAvatarHead(this.app, component,
      this.app.getSystemOrFail(UIBuilderSystem).getAdaptiveServiceByComponent(component), undefined, 0.26);
  }

  protected handleName(component: AvatarNamePanelComponent) {
    const characterEntity = component.entity.parent as Entity;
    AvatarHelpers.setUIName(
      this.app, component, characterEntity.getComponentFromParents(NetworkObjectComponent)?.netObject?.ownerId,
    );
  }

  protected handleSpikingState(component: AvatarNamePanelComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const netObject = component.entity.getComponentFromParents(NetworkObjectComponent)?.netObject;
    const { videoService } = this.app.chatsService || {};
    if (!netObject || !videoService) return;

    const status = videoService.getUserSpikingStatus(String(netObject.ownerId));
    const state = status ? UIDocumentElementState.Selected : UIDocumentElementState.Default;
    const nameElement = uIDocumentComponent.getElementById(ContainerId.UserName);
    if (nameElement) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nameElement.setState(state);
      const textElement = nameElement.children.find((ch) => ch instanceof ThreeMeshUI.Text);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (textElement) textElement.setState(state);
    }
  }

  protected handleActiveButtons(component: AvatarNamePanelComponent) {
    const uIDocumentComponent = component.entity.getComponentOrFail(UIDocumentComponent);
    const states = uIDocumentComponent.elementStateDataList;
    const { videoService } = this.app.chatsService || {};
    const netObject = component.entity.getComponentFromParents(NetworkObjectComponent)?.netObject;
    if (!netObject || !videoService) return;

    const cameraStatus = videoService.getUserVideoStatus(String(netObject.ownerId));
    const cameraEnabled = cameraStatus === VideoStatus.ActiveMain || cameraStatus === VideoStatus.ActiveMainAndShare;
    const state = {
      [VideoStatus.ActiveMain]: 'alt',
      [VideoStatus.ActiveMainAndShare]: 'alt',
      [VideoStatus.Muted]: UIDocumentElementState.Default,
      [VideoStatus.Disabled]: UIDocumentElementState.User,
      [VideoStatus.ActiveShare]: UIDocumentElementState.Default,
    }[cameraStatus];

    if (states[ButtonId.CamButton]?.state === UIDocumentElementState.Active && cameraStatus !== VideoStatus.Disabled) {
      videoService.turnUserCameraById(String(netObject.ownerId), !cameraEnabled);
    }

    setIconState(uIDocumentComponent, ButtonId.CamButton, state);
  }
}
