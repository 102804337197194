import { I18nDictionary } from './I18nDictionary';

export const ruDictionary: I18nDictionary = {
  components: {
    poweredOwn: {
      powered: '',
      by: '',
      ownverse: 'Вселенная.Онлайн',
    },
  },
  containers: {
    enterScreen: {
      spaceTitle: 'Вселенная.Онлайн: Пространство',
      spaceDefaultTitle: 'Вселенная.Онлайн',
      nameForm: {
        title: 'Введите имя',
        inputPlaceholder: 'Имя',
        inputCharTypeRule: 'Английские символы, буквенно-цифровые символы, дефисы, подчеркивания и тильды',
        inputCharLengthRule: 'Не менее 3 символов, не более 8',
      },
      continueButtonLabel: 'Продолжить',
      enterButtonLabel: 'Войти',
      welcomeMessage: 'Добро пожаловать',
    },
  },
  canvas: {
    welcomeScreen: {
      notTouchDevice: {
        mouseHelp: 'Мышь для вращения',
        keyboardHelp: 'WASD для движения',
        switchViewHelp: 'Вид от первого или третьего лица',
        runHelp: 'Бег или ходьба',
        danceHelp: 'Танец',
      },
      touchDevice: {
        dragHelp: 'Коснитесь и подвигайте пальцем, чтобы осмотреться',
        navigateHelp: 'Перемещайтесь, используя управление в круге',
        switchViewHelp: 'Вид от первого или третьего лица',
        runHelp: 'Бег или ходьба',
        danceHelp: 'Танец',
      },
      seatHelp: 'Иконка рядом с лавками, чтобы сесть; Esc, чтобы встать',
      videoHelp: 'Проигрывание контента',
      controls: 'Управление',
      startButtonLabel: 'Старт',
    },
    shopifyMenu: {
      openInShopify: 'Открыть в Store',
      openInShopify2: 'Открыть в Store',
      defaultTitle: 'Название товара',
      defaultContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus tellus id congue semper cras at tempus. Enim etiam accumsan volutpat eget pretium ut velit, augue nibh. Nunc, sed magna et eu sed elit malesuada hendrerit. Aenean scelerisque in massa blandit lectus et urna. ',
    },
    presentationMenu: {
      text: 'Текст',
    },
    playerMenu: {
      text: 'Текст',
      libraryButtonLabel: 'Список',
      libraryButtonLabel2: 'Список',
    },
    mainMenu: {
      switchTo: 'Устройство:',
      titleFirstPart: 'OW',
      titleSecondPart: 'NSpace',
      tabAvatars: 'Аватары',
      tabPlaces: 'Места',
      tabPlayers: 'Игроки',
      tabInvite: 'Позвать',
      shareLinkDescription: 'Поделиться ссылкой, чтобы позвать друзей',
      shareSocialMedia: 'Поделиться в социальных сетях',
      poweredByFirstPart: 'Вселенная.Онлайн',
      poweredBySecondPart: '',
    },
    avatarVideo: {
      waitingMessage: 'Ожидание...',
    },
    avatarName: {
      waitingMessage: 'Ожидание...',
    },
  },
};
