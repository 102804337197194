import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import { ConferenceServiceInterface, UserInfo } from '../types';
import AudioService from './Audio.service';
import VideoService from './Video.service';

export default class ConferenceService implements ConferenceServiceInterface {
  protected accessToken = '';

  protected refreshToken = '';

  protected $externService = VoxeetSDK;

  protected $audioService?: AudioService;

  protected $videoService?: VideoService;

  constructor(
    public readonly authKey: string,
    public readonly authSecret: string,
    public readonly tokenURL = 'https://session.voxeet.com/v1/oauth2/token',
  ) {
  }

  get externService() {
    return this.$externService;
  }

  get audioService() {
    return this.$audioService;
  }

  get videoService() {
    return this.$videoService;
  }

  setAudioService(service: AudioService) {
    this.$audioService = service;
    return this;
  }

  setVideoService(service: VideoService) {
    this.$videoService = service;
    return this;
  }

  public getAccessToken() {
    const authHeader = `Basic ${btoa(`${encodeURI(this.authKey)}:${encodeURI(this.authSecret)}`)}`;

    const tokenParams = {
      method: 'POST',
      headers: {
        Authorization: authHeader,
      },
      body: JSON.stringify({
        grant_type: 'client_credentials',
      }),
    };

    return fetch(this.tokenURL, tokenParams)
      .then((response) => response.json())
      .then(({ access_token, refresh_token }) => {
        this.accessToken = access_token;
        this.refreshToken = refresh_token;
        return access_token;
      });
  }

  public initialize() {
    return this.getAccessToken().then((token) => {
      this.externService.initializeToken(token, this.getAccessToken.bind(this));
    });
  }

  public connect(user: UserInfo) {
    return this.externService.session.open({
      name: user.name,
      externalId: user.id,
    });
  }

  public createAndJoinConference(roomName: string) {
    return this.externService.conference.create({
      alias: roomName,
      params: { videoCodec: 'VP8' },
    })
      .then((conf) => {
        return this.externService.conference.join(conf, {
          simulcast: false,
          constraints: {
            audio: false,
          },
        });
      })
      .then(() => {
        const promises = [];
        if (this.audioService) promises.push(this.audioService.afterJoin());
        if (this.videoService) promises.push(this.videoService.afterJoin());
        return Promise.all(promises);
      })
      .then(() => {});
  }

  public findUserById(externalId: string) {
    const [id, user] = Array.from(this.externService.conference.participants)
      .find(([name, part]) => part.info.externalId === externalId) || [null, null];
    return user;
  }
}
